import React from 'react';
import { Avatar, Box, Icon, Stack, Text, Tooltip } from '@angellist/adapt';

import {
  commitmentStatusFormatter,
  getCommitmentInvestorName,
  ruvStatusFormatter,
} from './utils';
import { FinancingCommitment, RuvInvestor } from '../../../graphql';
import { INSTRUMENT_TYPE, INVESTOR_SOURCE } from './constants';
import { CommitmentType } from './types';
import { pendingStatusFormatter } from '../../helpers/formatters';

export const investorNameFormatter = (
  _: any,
  commitment: FinancingCommitment,
) => {
  const investorName = getCommitmentInvestorName(commitment);
  const avatarShape =
    commitment.investmentEntity?.name === 'individual' ? 'circle' : 'square';
  const entityName = commitment?.entityName;

  return (
    <Box gap="75" display="flex" alignItems="center">
      <Avatar label={investorName} size="150" shape={avatarShape} />
      <Stack gap="0">
        <Text size="300" emphasis>
          {investorName}
        </Text>
        {entityName && (
          <Text color="text70" ellipsis>
            {entityName}
          </Text>
        )}
      </Stack>
    </Box>
  );
};

export const conversionInvestorNameFormatter = (
  _: any,
  commitment: FinancingCommitment,
) => {
  const investorName = getCommitmentInvestorName(commitment);
  const avatarShape =
    commitment.investmentEntity?.name === 'individual' ? 'circle' : 'square';

  return (
    <Box gap="75" display="flex" alignItems="center">
      <Avatar label={investorName} size="150" shape={avatarShape} />
      <Text size="300" emphasis>
        {investorName}
      </Text>
    </Box>
  );
};

export const sourceFormatter = (source: string) => {
  let icon = 'certificate';
  let title = 'Direct';
  if (source === INVESTOR_SOURCE.RUV) {
    icon = 'ruvs';
    title = 'RUV';
  }
  return (
    <Stack direction="horizontal" gap="50">
      <Icon named={icon as any} />
      <Text>{title}</Text>
    </Stack>
  );
};

export const signedSubStatusFormatter = (
  signedSubStatus: string | null,
  tooltipContent: string | null,
  status: string,
  isLoading: boolean,
) =>
  isLoading ? (
    pendingStatusFormatter(signedSubStatus, status, signedSubStatus, isLoading)
  ) : (
    <Tooltip content={<Text>{tooltipContent}</Text>}>
      {pendingStatusFormatter(
        signedSubStatus,
        status,
        signedSubStatus,
        isLoading,
      )}
    </Tooltip>
  );

export const stateFormatter = (
  state: string,
  commitment: CommitmentType,
  instrumentType?: string,
  equityDocsCollected?: boolean,
) => {
  if (commitment.source === INVESTOR_SOURCE.RUV) {
    const { subStatus } = commitment as RuvInvestor;
    return ruvStatusFormatter({
      status: state,
      subStatus,
      ...commitment?.pendingInvestorState,
    });
  }

  if (instrumentType === INSTRUMENT_TYPE.EQUITY) {
    return commitmentStatusFormatter(
      state,
      INSTRUMENT_TYPE.EQUITY,
      equityDocsCollected,
    );
  }
  return commitmentStatusFormatter(state);
};
